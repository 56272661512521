import React, { useState, useEffect, useCallback, useRef } from "react";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";
import { Api } from "./knihovna.jsx";
import { useCookies } from "react-cookie";
import NapovedaModal from "./napovedaModal.jsx";
import CompleteModal from "./completeModal.jsx";
import WrongModal from "./wrongModal.jsx";
import { useNavigate } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import logo from "../media/logo.png";

export default function Code() {
  const [cookies, setCookie, removeCookie] = useCookies(["name"]);
  const navigate = useNavigate();
  const [level, setCounter] = useState(1);
  const [ano, pridejAno] = useState(0);
  const [ne, pridejNe] = useState(0);
  const [hints, pridejHint] = useState(0);
  const [nesplnene, pridejNesplnene] = useState(0);
  const [visible, setVisible] = useState(true);
  const prvniRef = useRef(true);

  /* Result */
  let [reseni, zmenReseni] = useState(``);

  /* Modals */
  const [showModalNapoveda, setShowModalNapoveda] = React.useState(false);
  const [showModalComplete, setShowModalComplete] = React.useState(false);
  const [showModalWrong, setShowModalWrong] = React.useState(false);

  /* Code window */
  let [doplnCode, doplnSetCode] = useState(``);
  let [textZadani, zmenZadani] = useState(``);
  let [zadaniCode, zmenZadaniCode] = useState(``);
  const [nonEditStart] = useState(`public class Main {
    public static void main(String[] args) {`);
  const [nonEditEnd] = useState(`    }
}`);

  let [question, zmenQuestion] = useState("");
  function next() {
    let noveReseni = reseni.replace(/\s/g, "").replace(/"/g, `”`).toUpperCase();
    let novDoplnCode = doplnCode
      .replace(/\s/g, "")
      .replace(/"/g, `”`)
      .toUpperCase();

    if (novDoplnCode === noveReseni) {
      setShowModalComplete(true);
      pridejAno((ano) => ano + 1);
      dalsiOtazka();
      return;
    } else {
      setShowModalWrong(true);
      pridejNe((ne) => ne + 1);
      return;
    }
  }

  function dalsiOtazka() {
    resetCodu();
    setCounter((level) => level + 1);
    setEndTime(getCurrentTime());
    if (level === 8) {
      zmenZadani((textZadani = "HOTOVO!!!!"));
      const userTimeQuiz = ((endTime - startTime) / 1000).toFixed(0) * 1;
      let result = {
        rightAnswrs: ano,
        hints: hints,
        userTimeQuiz: userTimeQuiz,
      };
      sendScore(result);
      setVisible((prev) => !prev);
      document.getElementById("secondChangeMe").className =
        "mt-20 text-center font-bold text-5xl";
      document.getElementById("changeMe").className =
        "mt-5 text-center font-bold text-2xl";
      document.getElementById("idOfZadani").innerHTML = "";
      backToQR();
    } else {
      plsPomoc();
      return;
    }
  }

  function backToQR() {
    setTimeout(function () {
      navigate("/");
    }, 10000);
  }

  function exit() {
    setEndTime(getCurrentTime());
    const userTimeQuiz = ((endTime - startTime) / 1000).toFixed(0) * 1;
    let result = {
      rightAnswrs: ano,
      hints: hints,
      userTimeQuiz: userTimeQuiz,
    };
    sendScore(result);

    localStorage.removeItem("questionsData");
    removeCookie("userId");
    removeCookie("refreshToken");
    removeCookie("token");
    navigate("/");
  }

  function sendScore(result) {
    Api.addScore(cookies.userId, result, 3).then(() => {
      setCookie("refreshToken", null, { path: "/" });
      setCookie("userId", null, { path: "/" });
    });
  }

  const zmenHodnoty = useCallback(
    async (zadani, assignment, learn, code, answer) => {
      zmenQuestion(zadani);
      zmenZadani((textZadani) => assignment);
      zmenZadaniCode((zadaniCode) => learn);
      if (code === null) {
        doplnSetCode(" ");
      } else {
        doplnSetCode(code);
      }
      zmenReseni((reseni) => answer);
    },
    [zmenQuestion]
  );

  const plsPomoc = useCallback(async () => {
    const res = JSON.parse(localStorage.getItem("questionsData"));
    let otazka = level - 1;
    if (prvniRef.current === true) {
      otazka = level;
      prvniRef.current = false;
    }
    if (otazka < 8) {
      zmenHodnoty(
        res[otazka].question,
        res[otazka].questionAssignment,
        res[otazka].learn,
        res[otazka].code,
        res[otazka].answer
      );
    }
  }, [level, zmenHodnoty]);

  const getQuestions = useCallback(async () => {
    Api.getCodeQuestions().then((res) => {
      if (res != null) {
        localStorage.setItem("questionsData", JSON.stringify(res));
        plsPomoc();
      }
    });
  }, [plsPomoc]);

  useEffect(() => {
    const userId = cookies.userId;
    const refreshToken = cookies.refreshToken;

    if (userId == null || refreshToken == null) {
      navigate("/");
      return;
    }

    if (localStorage.getItem("questionsData") == null) {
      getQuestions();
    } else {
      plsPomoc();
    }
    setStartTime(getCurrentTime());
  }, [cookies.userId, cookies.refreshToken, navigate, getQuestions, plsPomoc]);

  function skipOtazka() {
    pridejNesplnene((nesplnene) => nesplnene + 1);
    dalsiOtazka();
  }

  function resetCodu() {
    doplnSetCode((doplnCode = `      `));
  }

  function ukazNapovedu() {
    setShowModalNapoveda(true);
    pridejHint((hints) => hints + 1);
  }

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  function getCurrentTime() {
    return new Date().getTime();
  }

  return (
    <div>
      <Disclosure as="nav" className="bg-gray-800 bg-peknaCerna text-bila">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <button className="cursor-default mt-4">
                      <img className="h-10 w-13" src={logo} alt="cehace" />
                    </button>
                  </div>
                  <h1 className="text-white font-bold text-3xl px-5">Java</h1>
                </div>

                <button onClick={() => exit()}>Ukončit</button>
              </div>
            </div>
          </>
        )}
      </Disclosure>

      <div className="w-100">
        {visible && (
          <header className="bg-zluta shadow mb-10">
            <div className="max-w-7xl px-4 py-6 sm:px-6 lg:px-8 mx-auto">
              {/* Přidejte mx-auto zde */}
              <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                Level {level} - {question}
              </h1>
            </div>
          </header>
        )}
        <div className="container max-w-7xl px-4 pb-6 sm:px-6 lg:px-8 mx-auto w-100">
          <div id="secondChangeMe" className="">
            <h2 className="text-2xl font-bold" id="idOfZadani">
              Zadání:
            </h2>
            <p>{textZadani}</p>
          </div>

          {visible && (
            <div>
              <br />
              <p className="font-bold text-2xl">Jak to udělat?</p>
              <p className="">{zadaniCode}</p>
              <br />
              {/* CODE WINDOW */}
              <div className="rounded-3xl border-4 mb-6">
                <div className="p-4">
                  <Editor
                    value={nonEditStart}
                    highlight={(nonEditStart) =>
                      highlight(nonEditStart, languages.js)
                    }
                    style={{
                      fontFamily: '"Fira code", "Fira Mono", monospace',
                      fontSize: 20,
                    }}
                    disabled
                  />

                  {/* JEHO CODE */}
                  <Editor
                    value={doplnCode}
                    onValueChange={(doplnCode) => doplnSetCode(doplnCode)}
                    highlight={(doplnCode) =>
                      highlight(doplnCode, languages.js)
                    }
                    style={{
                      fontFamily: '"Fira code", "Fira Mono", monospace',
                      fontSize: 20,
                    }}
                  />
                  <Editor
                    value={nonEditEnd}
                    highlight={(nonEditEnd) =>
                      highlight(nonEditEnd, languages.js)
                    }
                    style={{
                      fontFamily: '"Fira code", "Fira Mono", monospace',
                      fontSize: 20,
                    }}
                    disabled
                  />
                </div>
              </div>

              <div
                className="flex gap-2 rounded-md shadow-sm mt-2"
                role="group"
              >
                <button
                  onClick={next}
                  className="bg-zluta hover:bg-hoverZluta text-white font-bold py-2 px-4 border border-blue-700 rounded"
                >
                  Odevzdat
                </button>

                <button
                  onClick={skipOtazka}
                  className="hover:bg-seda text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                >
                  Další otázka
                </button>

                <button
                  onClick={() => ukazNapovedu()}
                  className="hover:bg-seda text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                >
                  Nápověda
                </button>

                <button className="hover:bg-seda text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                  <a href="#tutorial">Tutorial</a>
                </button>
              </div>
            </div>
          )}
          <div id="changeMe" className="mt-4">
            <p>Počet úspěšných otázek: {ano}</p>
            <p>Počet neúspěšných odpovědí: {ne}</p>
            <p>Počet nesplněných otázek: {nesplnene}</p>
            <p>Počet nápověd: {hints}</p>
          </div>
          {showModalNapoveda ? (
            <NapovedaModal
              showModalNapoveda={showModalNapoveda}
              setShowModalNapoveda={setShowModalNapoveda}
            />
          ) : null}
          {showModalComplete ? (
            <CompleteModal
              showModalComplete={showModalComplete}
              setShowModalComplete={setShowModalComplete}
            />
          ) : null}
          {showModalWrong ? (
            <WrongModal
              showModalWrong={showModalWrong}
              setShowModalWrong={setShowModalWrong}
            />
          ) : null}
        </div>

        <div className="w-100">
          {visible && (
            <footer className="bg-zluta shadow">
              <div
                className="max-w-7xl px-4 py-6 sm:px-6 lg:px-8 mx-auto"
                id="tutorial"
              >
                {/* Tutorial */}
                <h2 className="text-2xl font-bold">Tutorial:</h2>
                <p>Na konci kodu je vždy důležité napsat ";"</p>
                <p>Doplňuj kód tam, kde je písmeno "X"</p>
                <br />

                <ul>
                  <li>
                    <strong>Zvolte úroveň:</strong> Po spuštění hry uvidíte
                    aktuální úroveň a zadání úkolu.
                  </li>

                  <li>
                    <strong>Čtěte zadání:</strong> Zadání úkolu je zobrazeno v
                    textové podobě a někdy obsahuje užitečné nápovědy.
                  </li>

                  <li>
                    <strong>Doplňte chybějící kód:</strong> Vaším úkolem je
                    doplnit chybějící části kódu, které jsou označeny jako "X".
                  </li>

                  <li>
                    <strong>Okno pro kód:</strong> Uvidíte okno pro kód s
                    začátkem a koncem kódu, který nemůžete editovat.
                  </li>

                  <li>
                    <strong>Odevzdejte řešení:</strong> Po dokončení kódu
                    stiskněte tlačítko "Odevzdat".
                  </li>

                  <li>
                    <strong>Řešení:</strong> Pokud je váš kód správný, zobrazí
                    se modální okno s gratulací.
                  </li>

                  <li>
                    <strong>Další Otázka:</strong> Pokud nevíte a nechce se vám
                    nad kodem přemýšlet můžeš otázku přeskočit ale pozor, už se
                    k ní nebudeš moct vrátit
                  </li>

                  <li>
                    <strong>Nápověda:</strong> Pokud potřebujete nápovědu,
                    stiskněte tlačítko
                  </li>
                </ul>
              </div>
            </footer>
          )}

          {showModalNapoveda ? (
            <NapovedaModal
              showModalNapoveda={showModalNapoveda}
              setShowModalNapoveda={setShowModalNapoveda}
            />
          ) : null}

          {showModalComplete ? (
            <CompleteModal
              showModalComplete={showModalComplete}
              setShowModalComplete={setShowModalComplete}
            />
          ) : null}

          {showModalWrong ? (
            <WrongModal
              showModalWrong={showModalWrong}
              setShowModalWrong={setShowModalWrong}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
