import React from "react";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import logo from "../media/logo.png";

import QRCode from "react-qr-code";

export default function Home() {
  const [cookies, setCookie] = useCookies(["name"]);
  const navigate = useNavigate();

  const [message, setMessage] = useState("error");

  useEffect(() => {
    const userId = cookies.userId;
    const refreshToken = cookies.refreshToken;

    const handleWebSocketMessage = (event) => {
      console.log("Message from server:", event.data);
      const parsedMessage = JSON.parse(event.data).type;
      if (parsedMessage === "open") {
        const parsedMessage2 = JSON.parse(event.data).id;
        setMessage(
          JSON.stringify({ type: "codeQuestion", id: parsedMessage2 })
        );
      } else if (parsedMessage === "userConnected") {
        const parsedMessage2 = JSON.parse(event.data).id;
        const parsedMessage3 = JSON.parse(event.data).refreshToken;
        setCookie("refreshToken", parsedMessage3, { path: "/" });
        setCookie("userId", parsedMessage2, { path: "/" });
        navigate("/code");
      }

      console.log(parsedMessage);
    };

    if (userId != null && refreshToken != null) {
      navigate("/code");
    }
    const socket = new WebSocket("wss://apiweb.johnyserver.fun");
    socket.addEventListener("open", (event) => {
      const initialData = { type: "codeQuiz" };
      socket.send(JSON.stringify(initialData));
    });
    socket.addEventListener("message", handleWebSocketMessage);
    return () => {
      socket.removeEventListener("message", handleWebSocketMessage);
      socket.close();
    };
  }, [cookies.userId, cookies.refreshToken, navigate, setCookie]);

  return (
    <div>
      <Disclosure as="nav" className="bg-gray-800 bg-peknaCerna text-bila">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <button className="cursor-default mt-4">
                      <img className="h-10 w-13" src={logo} alt="cehace" />
                    </button>
                  </div>
                  <h1 className="text-white font-bold text-3xl px-5">Java</h1>
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>

      <div className="flex items-center justify-center">
        <div className="w-full bg-zluta p-4">
          <h1 className="text-center font-bold text-5xl text-peknaCerna mt-1">
            Naskenuj QR kód pro spuštění Java testu
          </h1>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="p-16">
          <QRCode style={{ height: "500px", width: "500px" }} value={message} />
        </div>
      </div>
    </div>
  );
}
