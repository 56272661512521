import React from "react";

const NapovedaModal = ({ showModalNapoveda, setShowModalNapoveda }) => {
  return showModalNapoveda ? (
    <>
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="modal-container bg-bila w-100 p-4 rounded-lg shadow-lg">
          <div className="modal-header flex justify-between border-b pb-2 mb-2">
            <h3 className="text-2xl font-semibold">Nápověda</h3>
            <button
              className="modal-close-button text-xl leading-none focus:outline-none"
              onClick={() => setShowModalNapoveda(false)}
            >
              ×
            </button>
          </div>
          <div className="modal-body p-2">
            <div className="relative p-6 flex-auto">
              <p className="my-4 text-slate-500 text-lg leading-relaxed">
                <span className="font-bold text-zluta">
                  Všeobecná nápověda:
                </span>{" "}
                <br />
                Za každým řádkem kodu musí být středník! (;) <br />
                Doplňuj kód tam, kde je písmeno "X"
                <br />
                <br />
                <span className="font-bold text-zluta">Proměné:</span> <br />
                int jmenoPromene = 0 (numerické proměné)
                <br />
                String jmenoPromene = "CHC" (textové proměné)
                <br />
                <br />
                <span className="font-bold text-zluta">Cykly:</span>
                <br />
                For loop: {"for(let i = 0; i < 10; i++){}"}
                <br />
                While loop: {"while(true){}"}
                <br />
                <br />
                <span className="text-zluta font-bold">Podmínka:</span>
                <br />
                {"if(i < 10){}"}
              </p>
            </div>
          </div>
          <div className="modal-footer mt-4 text-right">
            <button
              className="modal-button bg-zluta text-peknaCerna px-4 py-2 rounded hover:bg-hoverZluta"
              onClick={() => setShowModalNapoveda(false)}
            >
              Zavřít
            </button>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-25" />
    </>
  ) : null;
};

export default NapovedaModal;
