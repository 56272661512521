import React from "react";

const CompleteModal = ({ showModalWrong, setShowModalWrong }) => {
  return showModalWrong ? (
    <>
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="modal-container bg-bila w-96 p-4 rounded-lg shadow-lg">
          <div className="modal-header flex justify-between border-b pb-2 mb-2">
            <h3 className="text-2xl font-semibold">Špatná odpověď</h3>
            <button
              className="modal-close-button text-xl leading-none focus:outline-none"
              onClick={() => setShowModalWrong(false)}
            >
              ×
            </button>
          </div>
          <div className="modal-body p-2">
            <p>
              Zkus to jinak, kdyby si byl v koncích tak si můžeš zobrazit
              nápovědu.
            </p>
          </div>
          <div className="modal-footer mt-4 text-right">
            <button
              className="modal-button bg-zluta text-white px-4 py-2 rounded hover:bg-hoverZluta"
              onClick={() => setShowModalWrong(false)}
            >
              Zavřít
            </button>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-25" />
    </>
  ) : null;
};

export default CompleteModal;
